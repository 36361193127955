export class User {
    email: string;
    fullName: string;
    isAdmin: boolean;
    accessToken: string;
    refreshToken: string;
    remember: boolean;
    idAdmin: number;
    password: string;
    role: string;
    active: string;

    constructor(params: any) {
        this.isAdmin = params.isAdmin;
        this.accessToken = params.accessToken;
        this.fullName = params.fullName;
        this.idAdmin = params.idAdmin;
        this.password = params.password;
        this.refreshToken = params.refreshToken;
        this.role = params.role;
        this.remember = params.remember;
        this.email = params.email;
        this.active = params.active;
    }
}
