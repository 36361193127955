import { Routes } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'search',
    loadChildren: () => import('../../search/search.module').then(m => m.SearchModule)
  },
  {
    path: 'contracts',
    loadChildren: () => import('../../contracts/contracts.module').then(m => m.ContractsModule)
  },
  {
    path: 'stats',
    loadChildren: () => import('../../stats/stats.module').then(m => m.StatsModule)
  },
  {
    path: 'partner-stats',
    loadChildren: () => import('../../partner-stats/partner-stats.module').then(m => m.PartnerStatsModule)
  },
  {
    path: 'stats-total',
    loadChildren: () => import('../../stats-total/stats-total.module').then(m => m.StatsTotalModule)
  },
  {
    path: 'fees',
    loadChildren: () => import('../../fees/fees.module').then(m => m.FeesModule)
  },
  {
    path: 'users',
    loadChildren: () => import('../../users/users.module').then(m => m.UsersModule)
  },
];
